<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} Support Online Exam</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                  v-model="online_exam.category_id"
                  :items="support_categories"
                  item-text="name"
                  item-value="id"
                  @change="selectCategory($event)"
                  dense
                  outlined
                  :error="$v.online_exam.category_id.$error"
                  menu-props="closeOnContentClick"
              >
                <template v-slot:label>
                  Issue <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.online_exam.category_id.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="12" v-if="category && category.name == 'Others'">
              <v-text-field
                  v-model="online_exam.name"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                  v-model="selectCandidate"
                  :search-input.sync="userSearch"
                  :items="users"
                  :loading="isLoading"
                  item-text="full_name"
                  item-value="id"
                  @change="selectedCandidate"
                  :error="$v.online_exam.candidate_id.$error"
                  hide-selected
                  return-object
                  small-chips
                  deletable-chips
                  outlined
                  dense
                  clearable
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>Search by candidate name or ticket reference number  </strong>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:label>
                  Candidate <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.online_exam.candidate_id.$error">This information is required</span>
            </v-col>

            <v-col cols="12">
              Describe Your Issue <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="online_exam.description" >
              </ckeditor>
              <span class="text-danger" v-if="$v.online_exam.description.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  v-model="online_exam.device_type"
                  :error="$v.online_exam.device_type.$error"
                  :items="device_types"
                  item-text="name"
                  item-value="vale"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Device Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.online_exam.device_type.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  v-model="online_exam.device_os"
                  :error="$v.online_exam.device_os.$error"
                  :items="operating_systems"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Operating System <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.online_exam.device_os.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  v-model="online_exam.device_browser"
                  :error="$v.online_exam.device_browser.$error"
                  :items="browsers"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Browser <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.online_exam.device_browser.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="online_exam.error_on_question"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Question that I was doing when the error occurred (if known)
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              General Comments <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="online_exam.general_comment" >
              </ckeditor>
              <span class="text-danger" v-if="$v.online_exam.general_comment.$error">This information is required</span>
            </v-col>

            <v-col cols="12">
              <v-file-input
                  label="Attachment files"
                  v-model="online_exam.attachment_files"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  @change="previewImage"
                  multiple
                  clearable
                  counter
                  :show-size="1000"
                  outlined
                  dense
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                      v-if="index < 4"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                  >
                    {{ text }}
                  </v-chip>

                  <span
                      v-else-if="index === 4"
                      class="text-overline grey--text text--darken-3 mx-2"
                  >
                                +{{ online_exam.attachment_files.length - 4 }} File(s)
                           </span>
                </template>
              </v-file-input>
            </v-col>

            <v-col cols="12" v-if="images.length > 0">
              <v-row>
                <v-col cols="12" md="3" v-for="(image, index) in images" :key="index">
                  <v-img
                      :lazy-src="image"
                      max-height="150"
                      max-width="150"
                      :src="image"
                  ></v-img>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="online_exam.reply_email"
                  label="Reply Email Address"
                  outlined
                  dense
              ></v-text-field>
              <span class="text-danger" v-if="!$v.online_exam.reply_email.email">This Email is Should Valid</span><br>
              <small>
                Enter an email address so we can reply to you about the issue you experienced.
              </small>
            </v-col>

          </v-row>
        </v-container>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="btn btn-primary"
            dark
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
        <v-btn
            color="btn btn-standard"
            dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import SupportCategoryService from "@/services/support/support-category/SupportCategoryService";
import SupportOnlineExamService from "@/services/support/support-online-exam/SupportOnlineExamService";
import CandidateService from "@/services/candidate/CandidateService";

const candidate = new CandidateService();
const supportCategory = new SupportCategoryService();
const supportOnlineExam = new SupportOnlineExamService();
export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: "",
      dialog: false,
      edit: false,
      loading: false,
      isLoading: false,
      userSearch: null,
      category: {},
      selectCandidate: null,
      errors: [],
      users: [],
      support_categories: [],
      images: [],
      issues: [
        {name: "The app crashed.", value: "The app crashed."},
        {name: "The app shutdown unexpectedly", value: "The app shutdown unexpectedly"},
        {name: "The question didn't work properly.", value: "The question didn't work properly."},
        {name: "Others", value: "Others"},
      ],
      device_types: [
        {name: "MAC", value: "MAC"},
        {name: "PC", value: "PC"},
        {name: "Smartphone", value: "Smartphone"},
        {name: "iPad/Tablet", value: "iPad/Tablet"},
      ],
      operating_systems: [
        {name: "OS X Monterey", value: "OS X Monterey"},
        {name: "OS X Big Sur", value: "OS X Big Sur"},
        {name: "OS X Catalina", value: "OS X Catalina"},
        {name: "OS X Mojave", value: "OS X Mojave"},
        {name: "OS X High Sierra", value: "OS X High Sierra"},
        {name: "OS X Sierra", value: "OS X Sierra"},
        {name: "OS X El Capitan", value: "OS X El Capitan"},
        {name: "OS X Yosemite", value: "OS X Yosemite"},
        {name: "OS X Mavericks", value: "OS X Mavericks"},
        {name: "OS X Mountain Lion", value: "OS X Mountain Lion"},
        {name: "OS X Lion", value: "OS X Lion"},
        {name: "OS X Snow Leopard", value: "OS X Snow Leopard"},
        {name: "Win XP 2003", value: "Win XP 2003"},
        {name: "Android", value: "Android"},
        {name: "ISO", value: "ISO"},
        {name: "Windows 7", value: "Windows 7"},
        {name: "Windows 7", value: "Windows 7"},
        {name: "Windows 8", value: "Windows 8"},
        {name: "Windows 8.1", value: "Windows 8.1"},
        {name: "Windows 10", value: "Windows 10"},
        {name: "Windows 11", value: "Windows 11"},
        {name: "I don't know", value: "I don't know"},
      ],
      browsers: [
        {name: "Firefox", value: "Firefox"},
        {name: "Chrome", value: "Chrome"},
        {name: "Safari", value: "Safari"},
        {name: "Microsoft Edge", value: "Microsoft Edge"},
        {name: "Opera", value: "Opera"},
        {name: "I don't know", value: "I don't know"},
      ],
      online_exam: {
        name: '',
        category_id: '',
        candidate_id: '',
        score_id: '',
        description: '',
        device_type: 'MAC',
        device_os: 'OS X Monterey',
        device_browser: 'Chrome',
        device_browser_versions: '',
        error_on_question: '',
        general_comment: '',
        attachment_files: [],
        reply_email: ''
      },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
    }
  },
  validations: {
    online_exam: {
      category_id: { required },
      candidate_id: { required },
      description: { required },
      device_type: { required },
      device_os: { required },
      device_browser: { required },
      device_browser_versions: { required },
      general_comment: { required },
      reply_email: { email },
    }
  },
  mounted() {
    this.getSupportCategories();
    this.detectBrowser();
    this.detectOS();
    this.detectComputerType();
  },
  watch: {
    userSearch (val) {
      if (this.isLoading) return;
      this.isLoading = true;
      if(val != null) {
        candidate
            .getAllCandidate(val)
            .then((response) => {
              this.users =response.data.candidates
            })
            .catch(err => {

            })
            .finally(() => {
              this.isLoading = false
            })
      }
      this.isLoading = false
    }
  },
  methods: {
    selectCategory(event) {
      if(event != null) {
        this.support_categories.forEach(category => {
          if(category.id == event) {
            this.category = category;
          }
        });
      }
    },
    getSupportCategories() {
      supportCategory
          .getVisibleOnlineExam()
          .then(response => {
            this.support_categories = response.data.supportCategories;
          })
          .catch(err => {
          })
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createSupportOnlineExam() {
      this.openDialog();
      this.edit = false;
      this.title = "Add";
    },
    selectedCandidate() {
      if(this.selectCandidate != null) {
        this.online_exam.candidate_id = this.selectCandidate.id;
        this.online_exam.score_id = this.selectCandidate.score_id;
      }else {
        this.online_exam.candidate_id = '';
        this.online_exam.score_id = '';
      }
    },
    previewImage() {
      this.images = [];
      let length =  this.online_exam.attachment_files.length;
      if(length > 0) {
        for (let i = 0; i < length; i++) {
          const fileType = this.online_exam.attachment_files[i].type;
          if(i <= 3 && fileType.includes('image')) {
            this.images.push(URL.createObjectURL(this.online_exam.attachment_files[i]));
          }
          if(fileType.includes('officedocument')) {
            this.images.push('/assets/image/document.png');
          }
          if(fileType.includes('application/pdf')) {
            this.images.push('/assets/image/pdf.png');
          }
        }
      }
    },
    convertToFormData() {
      let formData = new FormData();
      if (this.online_exam.attachment_files && this.online_exam.attachment_files.length > 1) {
        for (const file in this.online_exam.attachment_files) {
          if (this.online_exam.attachment_files[file] != null && this.online_exam.attachment_files[file] !== undefined) {
            formData.append(`files[${file}]`, this.online_exam.attachment_files[file]);
          }
        }
      }
      if (this.online_exam.attachment_files.length === 1) {
        formData.append('files', this.online_exam.attachment_files[0]);
      }
      for (let key in this.online_exam) {
        if (key != 'attachment_files') {
          formData.append(key, this.online_exam[key]);
        }
      }
      return formData;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }else {
        this.loading = true;
        let form = this.convertToFormData();
        supportOnlineExam
            .create(form)
            .then(response => {
              this.$snotify.success("Online ticket added !!");
              this.detectBrowser();
              this.detectOS();
              this.detectComputerType();
              this.$emit('refresh');
              this.closeDialog();
              this.loading = false;
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
              this.loading = false;
            });
      }
    },
    resetForm() {
      this.online_exam = {
        name: '',
        candidate_id: '',
        score_id: '',
        description: '',
        device_type: 'MAC',
        device_os: 'OS X Monterey',
        device_browser: 'Chrome',
        device_browser_versions: '',
        error_on_question: '',
        general_comment: '',
        attachment_files: [],
        reply_email: ''
      }
      this.selectCandidate = null;
      this.images = [];
      this.$v.$reset();
    },
    detectBrowser() {
      const userAgent = navigator.userAgent;
      let browserName = '';
      let fullVersion = '';
      let verOffSet = '';
      let index = '';

      // In Opera
      if ((verOffSet = userAgent.indexOf("Opera"))!=-1) {
        browserName = "Opera";
        fullVersion = userAgent.substring(verOffSet+6);
        if ((verOffSet = userAgent.indexOf("Version"))!=-1)
          fullVersion = userAgent.substring(verOffSet+8);
      }
      // In MSIE
      else if ((verOffSet=userAgent.indexOf("MSIE"))!=-1) {
        browserName = "Microsoft Edge";
        fullVersion = userAgent.substring(verOffSet+5);
      }
      // In Chrome
      else if ((verOffSet=userAgent.indexOf("Chrome"))!=-1) {
        browserName = "Chrome";
        fullVersion = userAgent.substring(verOffSet+7);
      }
      // In Safari
      else if ((verOffSet=userAgent.indexOf("Safari"))!=-1) {
        browserName = "Safari";
        fullVersion = userAgent.substring(verOffSet+7);
        if ((verOffSet=userAgent.indexOf("Version"))!=-1)
          fullVersion = userAgent.substring(verOffSet+8);
      }
      // In Firefox
      else if ((verOffSet=userAgent.indexOf("Firefox"))!=-1) {
        browserName = "Firefox";
        fullVersion = userAgent.substring(verOffSet+8);
      }
      // In most other browsers
      else {
        browserName = "I don't know";
        fullVersion = "";
      }
      // trim the fullVersion string at semicolon/space if present
      if ((index = fullVersion.indexOf(";"))!=-1)
        fullVersion = fullVersion.substring(0,index);

      if ((index = fullVersion.indexOf(" "))!=-1)
        fullVersion = fullVersion.substring(0,index);

      this.online_exam.device_browser = browserName;
      this.online_exam.device_browser_versions = fullVersion;
    },

    detectOS() {
      this.online_exam.device_os = "I don't know";
      if (navigator.userAgent.indexOf("Windows NT 11.0") != -1)  this.online_exam.device_os = "Windows 11";
      if (navigator.userAgent.indexOf("Windows NT 10.0") != -1)  this.online_exam.device_os = "Windows 10";
      if (navigator.userAgent.indexOf("Windows NT 6.3") != -1)  this.online_exam.device_os = "Windows 8";
      if (navigator.userAgent.indexOf("Windows NT 6.2") != -1)  this.online_exam.device_os = "Windows 8";
      if (navigator.userAgent.indexOf("Windows NT 6.1") != -1)  this.online_exam.device_os = "Windows 7";
      if (navigator.userAgent.indexOf("Windows NT 6.0") != -1)  this.online_exam.device_os = "Windows Vista";
      if (navigator.userAgent.indexOf("Windows NT 5.1") != -1)  this.online_exam.device_os = "Win XP 2003";
      if (navigator.userAgent.indexOf("Windows NT 5.0") != -1)  this.online_exam.device_os = "Windows 2000";
    },

    detectComputerType() {
      this.online_exam.device_type = "PC";
      if (navigator.userAgent.indexOf("MAC OS X") != -1)  this.online_exam.device_os = "MAC";
    }
  }
}
</script>