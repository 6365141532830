<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Online candidate tickets
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Online candidate tickets
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn x-large text
                         @click="closedSupportOnlineExam"
                  >
                    Closed online candidate tickets
                  </v-btn>
                  <v-btn class="btn btn-primary font-weight-bolder font-size-sm"
                         style="color: #fff;"
                         @click="createSupportOnlineExam"
                  >
                    <i class="fa fa-plus"></i>
                    Add online ticket
                  </v-btn>


                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Name"
                      v-model="search.name"
                      outlined
                      dense
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportOnlineExam()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Enrolment Key"
                      v-model="search.enrol_key"
                      outlined
                      dense
                      @input="search.enrol_key = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportOnlineExam()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="current_user.access_type == 'federal'">
                  <v-select
                      label="SCORE"
                      v-model="search.score_id"
                      :items="scores"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      @input="search.score_id = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportOnlineExam()"
                      clearable
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchSupportOnlineExam()"
                      dense
                  ></v-select>
                </v-col>


                <v-col cols="12" md="2" class="text-right">
                  <v-btn
                      @click="searchSupportOnlineExam()"
                      class="btn btn-primary"
                      style="color: #fff"
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Request</th>
                  <th class="px-3">Candidate</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Exam</th>
                  <th class="px-3">SCORE</th>
                  <th class="px-3">Replied date</th>
                  <th class="px-3">Requested date</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in support_online_exams" :key="index">
                    <td style="max-width: 270px !important; white-space: pre-wrap;" class="px-2">
                      <a @click="viewOnlineExam(item.id)" class="d-flex align-items-center">
                        <span class=" font-weight-bolder">
                          {{ item.name }}
                        </span>
                        <span class="text-primary mb-1 mt-2 d-flex align-items-center" v-if="item.name == null">
                          {{item.category_name }}
                        </span>
                      </a>
                    </td>
                    <td class="px-3">
                      <a @click="goToCandidate(item.candidate_enrol_key)" class="text-primary mb-1 mt-2 d-flex align-items-center">
                        {{item.candidate_name}}
                      </a>
                      {{item.candidate_enrol_key}}
                    </td>
                    <td style="max-width: 270px !important; white-space: pre-wrap;" class="px-3">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.product_name ? item.product_name : '--'}}
                      </span>
                    </td>
                    <td style="max-width: 270px !important; white-space: pre-wrap;" class="px-3">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                       {{item.score_name ? item.score_name : '--'}}
                      </span>
                    </td>
                    <td class="px-3">
                      <a  class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.formatted_replied_date ? item.formatted_replied_date : '--'}}
                      </a>
                    </td>
                    <td class="px-3">
                      <a  class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.formatted_created_at ? item.formatted_created_at : '--'}}
                      </a>
                    </td>
                  </tr>

                  <tr v-if="support_online_exams.length == 0">
                    <td colspan="10" class="text-center">
                      <strong>No online candidate tickets available</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="support_online_exams.length > 0"
                  class="pull-right mt-7"
                  @input="getAllSupportOnlineExam"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>

        <create-and-update ref="create-and-update" @refresh="getAllSupportOnlineExam"></create-and-update>
      </div>
    </div>
  </v-app>
</template>

<script>
import SupportOnlineExamService from "@/services/support/support-online-exam/SupportOnlineExamService";
import ScoreService from "@/services/score/score/ScoreService";
import CreateAndUpdate from "@/view/pages/view/support/support-online-exam/CreateAndUpdate";

const supportOnlineExam = new SupportOnlineExamService();
const score = new ScoreService();
export default {
  name: "Index",
  components: {CreateAndUpdate},
  data() {
    return {
      search:{
        name: '',
        enrol_key: '',
        candidate_id: '',
        score_id: '',
        is_active: '',
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      candidateId: null,
      current_user: {},
      scores: [],
      support_online_exams: [],
      total: null,
      perPage: null,
      page: null,
      loading: false,
    }
  },
  methods:{
    createSupportOnlineExam(){
      this.$refs['create-and-update'].createSupportOnlineExam();
    },
    closedSupportOnlineExam(){
      this.$router.push({name: "support-online-exam-closed"});
    },
    goToCandidate(examKey){
      if(examKey) {
        this.$router.push({
          name: 'candidate-summary',
          params: {examKey: examKey}
        });
      }
    },
    viewOnlineExam(onlineExamId) {
      this.$router.push({
        name: 'support-online-exam-view',
        params: {onlineExamId: onlineExamId}
      })
    },
    getAllSupportOnlineExam(){
      this.loading =  true;
      supportOnlineExam
          .paginate(this.search,this.page)
          .then(response => {
            this.support_online_exams=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading =  false;
          })
          .catch((err) => {});
    },
    getAllScore(){
      this.loading =  true;
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
            this.loading =  false;
          })
          .catch((err) => {});
    },
    searchSupportOnlineExam(){
      this.getAllSupportOnlineExam();
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
  },
  mounted() {
    this.current_user = this.$store.getters.currentUser;
    this.candidateId = this.$route.params.candidateId;
    if(this.candidateId != null && this.candidateId != undefined) {
      this.search.candidate_id = this.candidateId;
    }
    this.getAllSupportOnlineExam();
    this.getAllScore();
    this.getCurrentUser();
  }
}
</script>
